import React from 'react';
import { useWallet } from 'contexts/Wallet';

import classes from 'components/NavBar/NavBar.module.scss';
import { APP_CONFIG } from 'config';

export const NavBar: React.FC = () => {
  const { account, connect, disconnect } = useWallet();

  return (
    <div className={classes.navbar}>
      <img src={APP_CONFIG.COMPANY.LOGO} alt={`${APP_CONFIG.COMPANY.NAME} Logo`} />
      <div>
        {account ? (
          <div className={classes.connected}>
            <div className={classes.walletAddress}>
              <p>{account}</p>
            </div>
            <div style={{ cursor: 'pointer' }} className={classes.button} onClick={disconnect}>
              <p>Disconnect Wallet</p>
            </div>
          </div>
        ) : (
          <div className={classes.button} onClick={connect}>
            Connect to a Wallet
          </div>
        )}
      </div>
    </div>
  );
};
